import { NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  isDevMode,
  OnInit,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { CmsComponent } from "../../interfaces/cms-component.interface";
import { Content } from "../../interfaces/content.interface";
import { ComponentManagerService } from "../../services/component-manager.service";

@Component({
  selector: "cg-cms-host-container",
  templateUrl: "./cms-host-container.component.html",
  styleUrls: ["./cms-host-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass]
})
export class CmsHostContainerComponent implements OnInit {
  @Input() public content: Content;
  @ViewChild("host", { read: ViewContainerRef, static: true })
  public host: ViewContainerRef;
  @HostBinding("class.grey-background")
  public hasGreyBackground: boolean;

  public constructor(private readonly componentManagerService: ComponentManagerService) {}

  public mountComponent(): void {
    if (!this.content) {
      return;
    }

    this.hasGreyBackground = this.content.bgColor === "grey";

    if (!Object.prototype.hasOwnProperty.call(this.content, "ngTemplate")) {
      throw new Error(
        `Can't render component for content without ngTemplate property. Content: ${JSON.stringify(this.content)}`
      );
    }

    const { ngTemplate } = this.content;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const component: any = this.componentManagerService.getComponentByTemplateId(ngTemplate);

    if (component) {
      this.host.clear();

      // is used for KontoroFeatureFlagKeys.CGX_STAGE_TEST
      // if test is done it can be removed
      const isCgxComponent = ngTemplate.startsWith("cgx");

      const componentInstance = this.host.createComponent(component);

      if (isCgxComponent) {
        componentInstance.setInput("content", this.content);
      } else {
        (componentInstance.instance as CmsComponent).content = this.content;
      }
    } else {
      if (isDevMode()) {
        // eslint-disable-next-line no-console
        console.log(`Skip rendering for ${this.content.ngTemplate}`);
      }
    }
  }

  public ngOnInit() {
    this.mountComponent();
  }
}
