import { inject, Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, mergeMap, switchMap, take, tap } from "rxjs/operators";
import { KontoroFeatureFlagKeys, KontoroFeatureFlagService, VariationMap } from "@cg/analytics";
import { ApiService } from "@cg/core/api";
import { ApiServiceIds } from "@cg/core/types";
import { Content } from "../interfaces/content.interface";
import { MagnoliaResponse } from "../interfaces/magnolia-response.interface";
import { staticCgxStageTest } from "../models/static-cgx-stage-test.model";
import { WebContentPlaceholderService } from "./web-content-placeholder.service";

@Injectable({
  providedIn: "root"
})
export class WebContentService {
  private readonly apiService = inject(ApiService);
  private readonly webContentPlaceholderService = inject(WebContentPlaceholderService);
  private readonly kontorolabFeatureFlagService = inject(KontoroFeatureFlagService);

  private readonly contentCache = new Map<string, MagnoliaResponse>();

  public getContentNodeFromService(path: string, variationMap: VariationMap): Observable<MagnoliaResponse> {
    path += this._getVariantQueryString(variationMap);

    if (this.contentCache.has(path)) {
      return of(this.contentCache.get(path));
    }

    return this.kontorolabFeatureFlagService
      .isFeatureFlagActive$(KontoroFeatureFlagKeys.CGX_REBRUSH, "variant1")
      .pipe(
        mergeMap((isCgxRebrushActive: boolean) =>
          this._fetchContentNodeWithServiceId(
            isCgxRebrushActive ? "webcontentCgxRebrush" : "webcontent",
            isCgxRebrushActive && !path.includes("/cgx-rebrush") ? `/cgx-rebrush/${path}` : path,
            variationMap
          ).pipe(
            catchError((err: Error) =>
              isCgxRebrushActive
                ? this._fetchContentNodeWithServiceId("webcontent", path, variationMap)
                : throwError(() => err)
            )
          )
        )
      );
  }

  private _handleRedirectLinkIfExists(
    response: MagnoliaResponse,
    variationMap: VariationMap
  ): Observable<MagnoliaResponse> {
    if (!response?.properties?.redirectLink) {
      return of(response);
    }

    // replace root it is part of the internal magnolia path but not part of the url
    const path = response.components?.[0]?.path?.replace("/root/", "/");

    if (!path) {
      return throwError(() => new Error("Redirect path is missing"));
    }

    return this.getContentNodeFromService(path, variationMap);
  }

  private _fetchContentNodeWithServiceId(
    serviceId: ApiServiceIds,
    path: string,
    variationMap: VariationMap
  ): Observable<MagnoliaResponse> {
    const additionalProps: Partial<MagnoliaResponse> = {
      timestamp: Date.now()
    };

    return this.apiService.get(serviceId, path, { disableRetry: true }).pipe(
      switchMap((response: MagnoliaResponse) => this._handleRedirectLinkIfExists(response, variationMap)),
      switchMap((response: MagnoliaResponse) =>
        this.webContentPlaceholderService.getContentWithReplacedPlaceholders(response.content).pipe(
          take(1),
          switchMap((contentWithPlaceholders: Content[]) =>
            this.kontorolabFeatureFlagService
              .isFeatureFlagActive$(KontoroFeatureFlagKeys.CGX_STAGE_TEST, "variant1")
              .pipe(map((active: boolean) => [contentWithPlaceholders, active]))
          ),
          map(([content, stageTestActive]: [Content[], boolean]) => {
            const processedContent = this._shouldReplaceStageHome(path, stageTestActive)
              ? this._replaceStageHome(content)
              : content;
            return [response, processedContent];
          })
        )
      ),
      map(([response, content]: [MagnoliaResponse, Content[]]) => ({
        ...response,
        content,
        ...additionalProps
      })),
      tap((response: MagnoliaResponse) => {
        this.contentCache.set(path, response);
      })
    );
  }

  private _getVariantQueryString(variationMap: VariationMap): string {
    let queryParams = "";
    for (const experimentId in variationMap) {
      if (experimentId in variationMap) {
        queryParams += queryParams.length === 0 ? "?" : "&";
        queryParams += "variant=" + experimentId + "-" + variationMap[experimentId].id;
      }
    }

    return queryParams;
  }

  private _shouldReplaceStageHome(path: string, stageTestActive: boolean) {
    const pathWithoutQueryParams = path.split("?")[0];

    return pathWithoutQueryParams === "/" && stageTestActive;
  }

  private _replaceStageHome(content: Content[]): Content[] {
    return content.map((c: Content) =>
      c.ngTemplate !== "cgStageHome" ? c : { ...staticCgxStageTest, picture: c.picture }
    );
  }
}
