import { inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { filter, map, withLatestFrom } from "rxjs/operators";
import { ConfigFacade } from "@cg/config";
import { SecurityFacade } from "@cg/core/api";
import type { configReducer } from "@cg/config";
import { Content } from "../interfaces/content.interface";

@Injectable({
  providedIn: "root"
})
export class WebContentPlaceholderService {
  private readonly configFacade = inject(ConfigFacade, { optional: true });
  private readonly securityFacade = inject(SecurityFacade, { optional: true });

  public getContentWithReplacedPlaceholders(content: Content[]): Observable<Content[]> {
    if (!this.configFacade) {
      return of(content);
    }

    return this.configFacade.configState$.pipe(
      withLatestFrom(this.securityFacade.accessToken$),
      filter(([configState, token]: [configReducer.ConfigState, string]) => !!configState && !!token),
      map(([configState, _token]: [configReducer.ConfigState, string]) =>
        this._replaceConfigPlaceholderWithValues(content, configState)
      )
    );
  }

  private _replaceConfigPlaceholderWithValues(content: Content[], configState: configReducer.ConfigState): Content[] {
    const CONFIG_REGEXP = /\[CONFIG:(?<group>[0-9a-z_]*):(?<key>[0-9a-z_]*)\]/gi;
    let contentString = JSON.stringify(content);

    for (const match of contentString.matchAll(CONFIG_REGEXP)) {
      const { group, key } = match?.groups ?? {};
      const rawValue = configState?.[group]?.[key];
      const value = this.formatValue(rawValue);

      if (!value) {
        console.error(`Invalid placeholder: ${match[0]}`);
        continue;
      }

      contentString = contentString.replaceAll(match[0], value);
    }

    return JSON.parse(contentString);
  }

  private formatValue(rawValue: string) {
    const isFloat = /^-?\d*[.,]?\d+$/.test(rawValue);

    if (!isFloat) {
      return rawValue;
    }

    const correctFloat = rawValue.replace(",", ".");

    return new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(Number(correctFloat));
  }
}
