import { noPaymentCheckedIcon, qualityCheckedIcon, timeCheckedIcon } from "@cg/icon";

export const staticCgxStageTest = {
  link: {
    href: "/olb",
    text: "Termin vereinbaren"
  },
  usps: {
    items: [
      {
        icon: noPaymentCheckedIcon,
        title: "<p>In der Regel kostenlos</p>\n"
      },
      {
        icon: timeCheckedIcon,
        title: "<p>Innerhalb eines Tages fertig</p>\n"
      },
      {
        icon: qualityCheckedIcon,
        title: "<p>Scheiben in Originalqualität</p>\n"
      }
    ],
    variation: "small",
    ngTemplate: "cgxUsps"
  },
  picture: null, // will be set on runtime
  title: "IHR AUTOGLAS EXPERTE NR.1",
  trustpilot: true,
  whitespace: "m" as "m" | "s" | "l",
  ngTemplate: "cgxStageHome"
};
