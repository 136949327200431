import type { AnalyticsConfiguration } from "@cg/core/interfaces";
import type { DeepPartialObject } from "@cg/core/types";
import { OptimizelyExperiment } from "@cg/core/enums";

export const analyticsConfiguration: DeepPartialObject<AnalyticsConfiguration> = {
  gtm: {
    allowedRouterEventUrls:
      /^\/standorte|\/olb|\/olb2b|\/kundenbwertungen|\/login|\/my-carglass|\/bewerbung|\/kfz-mechaniker-autoglas/,
    pageTracking: [
      { pathIsExactly: "/", type: "homepage", name: "homepage" },
      { pathStartsWith: "/olb", skip: true },
      { pathStartsWith: "/autoglas", type: "services" },
      { pathStartsWith: "/unternehmen", type: "company" },
      { pathStartsWith: "/ratgeber", type: "content" },
      { pathStartsWith: "/standorte", type: "search-for-branch" },
      { pathStartsWith: "/login/error", skip: true }, // skip because we track the login error page within the login tracking effects
      { pathStartsWith: "/login", skip: true }, // skip because we track the login page within the login component
      { pathStartsWith: "/my-carglass", type: "my-carglass" },
      { pathStartsWith: "/bewerbung", type: "recruiting" },
      { pathStartsWith: "/kfz-mechaniker-autoglas", type: "recruiting" },
      { pathStartsWith: "/protect-bestaetigung", type: "services" }
    ]
  },
  optimizely: {
    experimentScope: {
      [OptimizelyExperiment.LPN_ON_STAGE]: "/"
    },
    experiments: {
      [OptimizelyExperiment.BUSY_BANNER]: [
        { experimentId: "20728940399", variations: ["20686920475"] }, // Production
        { experimentId: "21472240684", variations: ["21490310039"] } // UAT
      ],
      [OptimizelyExperiment.LPN_ON_STAGE]: [{ experimentId: "20697770315", variations: ["20664910455"] }],
      [OptimizelyExperiment.BINDING_BOOKING]: [
        {
          experimentId: "20904950275",
          variations: ["20905050160", "21066150835", "21050721570"]
        }
      ],
      [OptimizelyExperiment.VIN_SCAN]: [
        { experimentId: "21979940095", variations: ["21950740090"] }, // UAT
        { experimentId: "21980080094", variations: ["21947950480"] } // PROD
      ],
      [OptimizelyExperiment.VAPS]: [
        { experimentId: "22327260501", variations: ["22337480303"] }, // UAT
        { experimentId: "22289181642", variations: ["22314490275"] } // PROD
      ],
      [OptimizelyExperiment.OPPORTUNITY_FUNNEL_PRODUCT_NOT_FOUND]: [
        { experimentId: "24655270074", variations: ["24603890543"] }, // Production
        { experimentId: "24631790378", variations: ["24623830290"] } // UAT
      ],
      [OptimizelyExperiment.OLB_SUMMARY]: [
        { experimentId: "27800270203", variations: ["27873450037"] }, // Production
        { experimentId: "27856420024", variations: ["27835350189"] } // UAT
      ],
      [OptimizelyExperiment.OLB_CONFIRMATION_CONTENT_RELEVANCY]: [
        { experimentId: "29361290231", variations: ["29342470216"] }, // Production
        { experimentId: "29322770824", variations: ["29361050745"] } // UAT
      ],
      [OptimizelyExperiment.OLB_EXIT_INTENT_SURVEY]: [
        { experimentId: "6362180873355264", variations: ["5172524861358080"] }, // Production
        { experimentId: "5456621546504192", variations: ["5069580115640320"] } // UAT
      ]
    }
  },
  shouldTrackInitialPage: true
};
